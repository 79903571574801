export const ServiceUrl = {
	ADMISSION: {

		SETTINGS: 'v1/settings',
		LIST_VARIABLE_SETTINGS: 'v1/admin/settings/variables',
		UPDATE_VARIABLE_SETTINGS: 'v1/admin/settings/variables.update',
		ADD_VARIABLE_SETTINGS: 'v1/admin/settings/variables.add',

		LIST_SMS_TEMPLATES: 'v1/admin/settings/smstemplates',
		UPDATE_SMS_TEMPLATES: 'v1/admin/settings/smstemplates.update',
		ADD_SMS_TEMPLATES: 'v1/admin/settings/smstemplates.add',
		DELETE_SMS_TEMPLATES: 'v1/admin/settings/smstemplates.delete',
		
		LIST_MAIL_TEMPLATES: 'v1/admin/settings/mailtemplates',
		UPDATE_MAIL_TEMPLATES: 'v1/admin/settings/mailtemplates.update',
		ADD_MAIL_TEMPLATES: 'v1/admin/settings/mailtemplates.add',
		DELETE_MAIL_TEMPLATES: 'v1/admin/settings/mailtemplates.delete',

		LIST_PAGES: 'v1/admin/settings/pages',
		UPDATE_PAGE: 'v1/admin/settings/pages.update',
		VIEW_PAGE: 'v1/admin/settings/pages.view',
		DELETE_PAGE: 'v1/admin/settings/pages.delete',
		ADD_PAGE: 'v1/admin/settings/pages.add',
		
		USER_PROFILE: 'v1/admin/settings/myprofile',
		USER_CHANGE_PASSWORD: 'v1/admin/settings/myprofile/changepassword',

		NEW_USER_LOGIN: 'v1/admin/user.new',
		LIST_USERS_LOGIN: 'v1/admin/users.list',
		USER_STATUS_CHANGE: 'v1/admin/user.status_change',
		REMOVE_USER_LOGIN: 'v1/admin/user.remove',
		UPDATE_USER_LOGIN: 'v1/admin/user.update',
		PASS_USER_LOGIN: 'v1/admin/user.pass',
		
	},
	CASHIER: {
		GET_NEW_BILL_ID: 'v1/admin/bill.get_new_id',
		SEARCH_PATIENT_BILLS_FOR_BILLING: 'v1/admin/bill.search_for_new_billing',
		SAVE_OP_INVOICE: 'v1/admin/bill.save_op',
		SAVE_INVOICE: 'v1/admin/bill.save',
		GET_OP_INVOICE: 'v1/admin/bill.get_op_bill',
		LIST_OP_INVOICES: 'v1/admin/bill.list_by_date',
		LIST_OP_INVOICES_BYCATEGORY: 'v1/admin/bill.list_by_date_category',
		LIST_OP_INVOICES_BYCATEGORY_GROUP: 'v1/admin/bill.list_by_date_category_group',
		CATEGORY_GROUP_NEW: 'v1/admin/bill.categorygroup.new',
		CATEGORY_GROUP_LISTALL: 'v1/admin/bill.categorygroup.listall',
		CATEGORY_GROUP_REMOVE: 'v1/admin/bill.categorygroup.remove',
		CATEGORY_GROUP_UPDATE: 'v1/admin/bill.categorygroup.update',
		CATEGORY_NEW: 'v1/admin/bill.category.new',
		CATEGORY_LISTALL: 'v1/admin/bill.category.listall',
		CATEGORY_REMOVE: 'v1/admin/bill.category.remove',
		CATEGORY_UPDATE: 'v1/admin/bill.category.update',
		CANCEL_INVOICE: 'v1/admin/bill.cancel',
		CANCELLED_LIST: 'v1/admin/bill.cancelled_list',
		FIND_INVOICE: 'v1/admin/bill.find',
		DISCHARGE_BILL_REQ: 'v1/admin/bill.discharge_bill_request',
		SAVE_DISCHARGE_BILL: 'v1/admin/bill.discharge_newbill',
		SAVE_BILL_GROUP: 'v1/admin/bill.bill_group.new',
		LIST_BILL_GROUPS: 'v1/admin/bill.bill_group.list',
		REMOVE_BILL_GROUP: 'v1/admin/bill.bill_group.remove',
		UPDATE_BILL_GROUP: 'v1/admin/bill.bill_group.update',
	},
	PATIENTS: {
		PATIENT_ID: 'v1/admin/patient.id',
		SEARCH_BY_ID: 'v1/admin/patients.search_by_id',
		LAST_IP_BY_ID: 'v1/admin/patients.get_last_ip',
		LIST_INVOICES_BY_PATIENT: 'v1/admin/bill.list_by_patient',
		SAVE_PATIENT: 'v1/admin/patient.new',
		LIST_PATIENTS_WITH_LIMIT: 'v1/admin/patients.list',
		REMOVE_PATIENT: 'v1/admin/patient.remove',
		UPDATE_PATIENT: 'v1/admin/patient.update',
		SAVE_PATIENT_ADVANCE: 'v1/admin/patient.save_advance',
		LIST_PATIENT_ADVANCE: 'v1/admin/patient.list_advance_by_patient',
	},
	LABORATORY: {
		TEST_LIST_WITH_GROUP: 'v1/admin/laboratory.test_list_with_group',
		SAVE_NEW_TEST_REQUEST: 'v1/admin/laboratory.save_request',
		TEST_LIST_BY_DATE: 'v1/admin/laboratory.test_list_bydate',
		TEST_LIST_BY_CONDITION: 'v1/admin/laboratory.test_list_bycond',
		SAVE_TEST_RESULT: 'v1/admin/laboratory.save_result',
		SAVE_TEST_GROUP: 'v1/admin/laboratory.save_group',
		LIST_TEST_GROUP: 'v1/admin/laboratory.list_groups',
		REMOVE_TEST_GROUP: 'v1/admin/laboratory.remove_group',
		UPDATE_TEST_GROUP: 'v1/admin/laboratory.update_group',
		LIST_TEST_LIST: 'v1/admin/laboratory.list_test',
		REMOVE_TEST_LIST: 'v1/admin/laboratory.remove_test',
		SAVE_TEST_LIST: 'v1/admin/laboratory.add_test',
		UPDATE_TEST_LIST: 'v1/admin/laboratory.update_test',
		LIST_TEST_PROFILES: 'v1/admin/laboratory.list_profiles',
		REMOVE_TEST_PROFILES: 'v1/admin/laboratory.remove_profile',
		SAVE_TEST_PROFILES: 'v1/admin/laboratory.save_profile',
		UPDATE_TEST_PROFILES: 'v1/admin/laboratory.update_profile',
		LIST_PROFILES_TEST: 'v1/admin/laboratory.list_profile_test',
		SAVE_PACKAGE: 'v1/admin/laboratory.new_package',
		LIST_PACKAGE: 'v1/admin/laboratory.list_package',
		DELETE_PACKAGE: 'v1/admin/laboratory.delete_package',
		LIST_PACKAGE_DATA: 'v1/admin/laboratory.list_package_data',
		UPDATE_PACKAGE: 'v1/admin/laboratory.update_data',
	},
	RECEPTION: {
		TOKEN_NO: 'v1/admin/reception.token_no',
		SAVE_TOKEN: 'v1/admin/reception.save_token',
		LIST_TOKENS: 'v1/admin/reception.list_tokens',
		GET_TOKEN: 'v1/admin/reception.get_token',
	},
	IP: {
		SAVE_FLOOR: 'v1/admin/floors.new',
		LIST_FLOORS: 'v1/admin/floors.list',
		REMOVE_FLOOR: 'v1/admin/floors.remove',
		UPDATE_FLOOR: 'v1/admin/floor.update',
		SAVE_WARD: 'v1/admin/ward.new',
		LIST_WARDS: 'v1/admin/wards.list',
		REMOVE_WARD: 'v1/admin/ward.remove',
		UPDATE_WARD: 'v1/admin/ward.update',
		SAVE_ROOM_TYPE: 'v1/admin/rommtype.new',
		LIST_ROOM_TYPES: 'v1/admin/rommtypes.list',
		REMOVE_ROOM_TYPE: 'v1/admin/rommtype.remove',
		UPDATE_ROOM_TYPE: 'v1/admin/rommtype.update',
		SAVE_ROOM: 'v1/admin/room.new',
		LIST_ROOMS: 'v1/admin/rooms.list',
		REMOVE_ROOM: 'v1/admin/room.remove',
		UPDATE_ROOM: 'v1/admin/room.update',

		IP_PATIENT_LAST_OP: 'v1/admin/ip.get_patient_op',
		IP_NO: 'v1/admin/ip.no',
		NEW_ADMISSION: 'v1/admin/ip.admission',
		LIST_ADMISSIONS: 'v1/admin/ip.admission_list',
		SAVE_ROOM_CHANGE: 'v1/admin/ip.room_change',
		SAVE_DISCHARGE: 'v1/admin/ip.discharge',

		NEW_CASE_HISTORY: 'v1/admin/ip.case_history_new',
		LIST_CASE_HISTORY: 'v1/admin/ip.case_history_list',

		SAVE_MANUAL_SUMMARY: 'v1/admin/discharge.manual_summary/save-data',
		LIST_MANUAL_SUMMARY: 'v1/admin/discharge.manual_summary/list-data',
		REMOVE_MANUAL_SUMMARY: 'v1/admin/discharge.manual_summary/remove-data',
		GET_MANUAL_SUMMARY: 'v1/admin/discharge.manual_summary/get-data',
		UPDATE_MANUAL_SUMMARY: 'v1/admin/discharge.manual_summary/update-data',
	},
	DOCTORS: {
		LIST_ALL: 'v1/admin/dept.list',
		NEW_DOCTOR: 'v1/admin/dept.new',
		REMOVE_DOCTOR: 'v1/admin/dept.remove',
		UPDATE_DOCTOR: 'v1/admin/dept.update',
		NEW_CONSULTANT: 'v1/admin/consultant.new',
		LIST_CONSULTANTS: 'v1/admin/consultant.list',
		REMOVE_CONSULTANT: 'v1/admin/consultant.remove',
		UPDATE_CONSULTANT: 'v1/admin/consultant.update',
	},
	ACCOUNTS: {
		LIST_ACCOUNT_TYPES: 'v1/admin/accounts.list_types',
		LIST_ACCOUNT_GROUPS: 'v1/admin/accounts.list_group',
		SAVE_ACCOUNT_GROUP: 'v1/admin/accounts.save_group',
		DELETE_ACCOUNT_GROUP: 'v1/admin/accounts.delete_group',
		UPDATE_ACCOUNT_GROUP: 'v1/admin/accounts.update_group',
		SAVE_ACCOUNT_LEDGER: 'v1/admin/accounts.save_ledger',
		LIST_ACCOUNT_LEDGERS: 'v1/admin/accounts.list_ledgers',
		REMOVE_ACCOUNT_LEDGER: 'v1/admin/accounts.remove_ledgers',
		UPDATE_ACCOUNT_LEDGER: 'v1/admin/accounts.update_ledger',
		ACCOUNT_LEDGER: 'v1/admin/accounts.listaccount',
		SAVE_ACCOUNT_BANK: 'v1/admin/accounts.save_bank',
		LIST_ACCOUNT_BANKS: 'v1/admin/accounts.list_banks',
		REMOVE_ACCOUNT_BANKS: 'v1/admin/accounts.remove_banks',
		UPDATE_ACCOUNT_BANK: 'v1/admin/accounts.update_bank',
		SAVE_PAYMENT_OUT: 'v1/admin/accounts.save_paymentout',
		LIST_PAYMENT_OUT: 'v1/admin/accounts.list_payments',
		LIST_SINGLE_PAYMENT: 'v1/admin/accounts.list_payment',
		LIST_DAY_BOOK: 'v1/admin/accounts.day_book',
	},
	HR: {
		SAVE_GRADE: 'v1/admin/hr.save_grade',
		LIST_GRADES: 'v1/admin/hr.list_gardes',
		REMOVE_GRADE: 'v1/admin/hr.remove_grade',
		UPDATE_GRADE: 'v1/admin/hr.update_grade',
		SAVE_BREAKUP_HEAD: 'v1/admin/hr.save_breakuphead',
		LIST_BREAKUP_HEADS: 'v1/admin/hr.list_breakuphead',
		REMOVE_BREAKUP_HEAD: 'v1/admin/hr.remove_breakuphead',
		UPDATE_BREAKUP_HEAD: 'v1/admin/hr.update_breakuphead',
		EMPLOYEE_NEW_CODE: 'v1/admin/hr.employee_code',
		SAVE_EMPLOYEE: 'v1/admin/hr.employee_save',
		LIST_EMPLOYEES: 'v1/admin/hr.employees',
		REMOVE_EMPLOYEE: 'v1/admin/hr.employee_remove',
		UPDATE_EMPLOYEE: 'v1/admin/hr.employee_update',
		EMPLOYEE_BY_CODE: 'v1/admin/hr.employee_by_code',
		SAVE_BIOMETRIC_DEVICE: 'v1/admin/hr.device_save',
		LIST_BIOMETRIC_DEVICES: 'v1/admin/hr.device_list',
		REMOVE_BIOMETRIC_DEVICE: 'v1/admin/hr.device_remove',
		UPDATE_BIOMETRIC_DEVICE: 'v1/admin/hr.device_update',
		SAVE_HOLIDAY: 'v1/admin/hr.holiday_save',
		LIST_HOLIDAYS: 'v1/admin/hr.holiday_list',
		REMOVE_HOLIDAY: 'v1/admin/hr.holiday_remove',
		ATT_MONTH_LOG: 'v1/admin/hr.att_month_log',
	},
	FIELD_SETTINGS: {
		NEW: 'v1/field_settings/new',
		LIST: 'v1/field_settings/all',
		REMOVE: 'v1/field_settings/delete',
		UPDATE: 'v1/field_settings/update',
	},
	SMS: {
		CHECK_BALANCE : 'v1/admin/sms/balance',
	},
	USERS: {
		STAFFS: {
			NEW: 'v1/users/staff/new',
			UPDATE: 'v1/users/staff/update',
			UPDATE_PASSWORD: 'v1/users/staff/update_pass',
			UPDATE_STATUS: 'v1/users/staff/update_status',
			LIST_ALL: 'v1/users/staffs',
			REMOVE: 'v1/users/staffs/delete',
			VIEW_PASS: 'v1/users/staffs/pass',
			SAVE_SUBJECT_ALLOCATION: 'v1/users/staff/save_subject',
			LIST_ALLOCATED_SUBJECTS: 'v1/users/staff/list_staff_subjects',
			UPDATE_ALLOCATED_SUBJECT_STATUS: 'v1/users/staff/update_subject_status',
			REMOVE_ALLOCATED_SUBJECT: 'v1/users/staff/delete_subject',
		},
		STAFF_POSITIONS: {
			NEW: 'v1/users/staff/position/new',
			LIST_ALL: 'v1/users/staffs/positions',
			REMOVE: 'v1/users/staffs/position/delete',
			UPDATE: 'v1/users/staff/position/update',
		},
	},
	SUBJECTS: {
		BY_COURSE: 'v1/subjects.by_course',
		BY_EXAM: 'v1/subjects.by_exam',
	},
};